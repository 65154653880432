import Vue from 'vue'
import axios from 'axios'


const baseApiUrl = process.env.VUE_APP_API_URL
Vue.prototype.$api_url = baseApiUrl

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true
Vue.prototype.$http = axios

Vue.prototype.$sanctum = function () {
  return new Promise(function (resolve, reject) {
    if (document.cookie.indexOf('XSRF-TOKEN=')!=-1) {
      resolve(true)
      return;
    }
    axios.get(baseApiUrl+'/sanctum/csrf-cookie').then(response => {
      resolve(response)
    }).catch(err => reject(err));
  });
}